import * as React from "react";
import { listServices } from "../../styles/components/lists.module.scss";
import { Link } from "gatsby";

const ServicesList = () => {
  // Function to check if the code is running in the browser
  const isBrowser = typeof window !== 'undefined';

  // Function to check if the view is mobile
  const isMobileView = () => {
    return isBrowser && window.innerWidth <= 767; // Adjust the breakpoint as needed
  };

  const [isActive, setActive] = React.useState(isMobileView());

  // Update the isActive state based on the current view
  React.useEffect(() => {
    const handleResize = () => {
      setActive(isMobileView());
    };

    // Add event listener for window resize only if running in the browser
    if (isBrowser) {
      window.addEventListener('resize', handleResize);

      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isBrowser]);
  return (
    <>
      <ul className={listServices}>
        
        <li>
          <Link to="/services/8-hour-private-party-boat-charter">
            <span>
              8 <small>Hr</small>
            </span>{" "}
            8-Hour Private Charter{" "}
            <span>
              <strong>$1,024+</strong> &middot; 12 Guests &middot; Captained &middot; Any Day
            </span>
          </Link>
        </li>
        <li>
          <Link to="/services/6-hour-private-party-boat-charter">
            <span>
              6 <small>Hr</small>
            </span>{" "}
            6-Hour Private Charter{" "}
            <span>
              <strong>$924+</strong> &middot; 12 Guests &middot; Captained &middot; Any Day
            </span>
          </Link>
        </li>
        <li>
          <Link to="/services/4-hour-private-party-boat-charter">
            <span>
              4 <small>Hr</small>
            </span>{" "}
            4-Hour Private Charter{" "}
            <span>
              <strong>$824+</strong> &middot; 12 Guests &middot; Captained &middot; Any Day
            </span>
          </Link>
        </li>
        <li>
          <Link to="/services/sunset-private-party-boat-charter">
            <span>
              3 <small>Hr</small>
            </span>{" "}
            Sunset Private Charter{" "}
            <span>
              <strong>$549+</strong> &middot; 12 Guests &middot;Captained &middot; Any Day
            </span>
          </Link>
        </li>
        <li>
          <Link to="/services/3-hour-wave-rave-djd-nightime-party-boat-charter">
            <span>
              3 <small>Hr</small>
            </span>{" "}
           3- Hour Wave Rave Charter
            <span>
              <strong>$825+</strong> &middot; 12 Guests &middot;Captained &middot; Any Day
            </span>
          </Link>
        </li>
        <li>
          <Link to="/services/exclusive-island-overnight-party">
            <span style={{ left: "0.5rem",  width: "100px"}}>
            12+ <small style={{display: "flex", right: "3.5rem", marginLeft: "2rem"  }} >Hr</small>
            </span>{" "}
            Island Overnight Party
            <span>
            <strong>$1,749</strong> &middot; 12 Guests &middot; By Reservation
            </span>
          </Link>
        </li>
        {/* {isActive &&(
          <li>
          <Link to="/services/exclusive-island-overnight-party">
            <span style={{ left: "0.5rem" }}>
              12+ <small style={{ left: "3.2rem", top: "0.6rem" }}>Hr</small>
            </span>{" "}
            Island Overnight Party
            <span>
              <strong>$1,749</strong> &middot; 12 Guests &middot; By Reservation
            </span>
          </Link>
        </li>
        )}

        {!isActive &&(
         <li style={{ flexBasis: "69%", padding: "17px 23px 0px 35px", display: "flex", alignItems: "center" }}>
         <div style={{ textAlign: "center", width: "100%" }}>
           <Link to="/services/exclusive-island-overnight-party">
             <span style={{ left: "1.5rem" }}>
               12+ <small style={{ left: "3.2rem", top: "0.6rem" }}>Hr</small>
             </span>{" "}
             <span style={{ display: "inline-block", maxWidth: "760px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
               Exclusive Island Overnight Party Charter
             </span>{" "}
             <span>
               <strong>$1,749</strong> &middot; 12 Guests &middot; By Reservation
             </span>
           </Link>
         </div>
       </li>
        )} */}
      </ul>
     
    </>
  );
};
export default ServicesList;
