import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Welcome to Tampa Bay Boating Adventures | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Group of friends partying on a boat in Tampa Bay, FL."
                  src="../../images/blog/group-of-friends-hanging-out-on-party-boat-in-tampa-bay.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>Welcome to Tampa Bay Boating Adventures</h1>
                  <p>August 5, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <h2>Tampa Bay Boating Adventures is Ready to Get Your Next Party on the Water Started!</h2>
                    <h3>Our party boat charter services are the best in the Tampa Bay, FL area.</h3>
                    <p>Tampa Bay Boating Adventures is the hottest, wildest party boat charter service in the Tampa Bay, Florida area. Hop on our state-of-the-art party boat and enjoy a <Link to="/services/4-hour-private-party-boat-charter">four</Link>, <Link to="/services/6-hour-private-party-boat-charter">six</Link>, or <Link to="/services/8-hour-private-party-boat-charter">eight hour</Link> party charter on the beautiful waters of the Tampa, Florida coastline. You'll see famous Tampa sights like Beer Can Island, the Tampa Riverwalk, gorgeous private beaches, Armature Works, and more! If you want a more relaxed evening with a beautiful Florida sunset, we offer an <Link to="/services/sunset-private-party-boat-charter">exclusive sunset party boat charter</Link>.</p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Girls and guys with drinks on a party boat in Tampa Bay, FL."
                          src="../../images/banners/girls-and-guys-with-drinks.jpg"
                        />
                      </div>
                    </div>
                    <p>Are you ready to get out on the water and enjoy the Tampa coast's exciting sandbars, landmarks, hot spots, and private islands with your best friends? Take a look at <Link to="/services">our party boat charter services</Link> or choose a package below to get started!</p>
                    <hr />
                    <h2>Select Your Party Boat Charter</h2>
                    <h3>See what all's included with our wide variety of party boat charter packages.</h3>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage